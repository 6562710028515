import type { TBackgroundColor } from '~/types/Shared.types';

export enum EHeaderBalanceTypes {
  COINS = 'coins',
  MONEY = 'money',
  SKINS = 'skins',
}

export interface IHeaderBalanceCoins {
  color: TBackgroundColor;
  icon: string;
  type: EHeaderBalanceTypes.COINS;
}

export interface IHeaderBalanceOther {
  type: EHeaderBalanceTypes.MONEY | EHeaderBalanceTypes.SKINS;
  showIcon?: boolean;
}

export interface IHeaderBalanceProps {
  amount: number;
  variant: IHeaderBalanceCoins | IHeaderBalanceOther;
}
